import React from "react";
import UserListEntry, { UserListEntryType } from "./UserListEntry";

type UserListPropType = {
  users?: UserListEntryType[];
};

export default function UserList({ users }: UserListPropType) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <td>Name</td>
            <td>{/* For Chips and stuff */}</td>
            <td>Company</td>
            <td>Email</td>
          </tr>
        </thead>
        {users?.map((user) => (
          <UserListEntry user={user} key={user.email} />
        ))}
      </table>
    </div>
  );
}
