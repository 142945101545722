import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Context } from "../../components/store";
import UserList from "../../components/user-management/UserList";
import { UserListEntryType } from "../../components/user-management/UserListEntry";
import { addAxiosError } from "../../utils/errors";
import { urlService } from "../../_services/urlService";

export default () => {
  const [, setContext] = useContext(Context);
  const [results, setResults] = useState([]);
  const [filter, setFilter] = useState("");
  const [users, setUsers] = useState([] as UserListEntryType[]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilter(event.target.value);
  };

  const debouncedChangeHandler = useMemo(() => _.debounce(handleChange, 500), []);

  useEffect(() => {
    const url = urlService.getUrl("/api/admin/users");

    urlService
      .getAxios()
      .get(url)
      .then((res) => {
        const users = res.data.users.map((user: any) => {
          var u = _.pick(user, ["name", "surname", "email", "isAdmin", "isActive"]) as UserListEntryType;
          u.companyName = user.company?.name;
          return u;
        });
        setUsers(users);
      })
      .catch((err) => {
        addAxiosError(err);
      });
  }, [filter]);

  return (
    <div>
      <h2>Benutzer</h2>
      {/* TODO: brauchen wir das? */}
      {/* <Input className="form-control mx-4 my-4" type="text" placeholder="Filter" onChange={debouncedChangeHandler} /> */}
      <div className="mx-auto w-fit">
        <UserList users={users} />
      </div>
    </div>
  );
};
