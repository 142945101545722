import { Chip } from "@mui/material";
import React from "react";

export type UserListEntryType = {
  email: string;
  name: string;
  surname: string;
  isAdmin: boolean;
  isActive: boolean;
  companyName: string;
};

type UserListEntryPropType = {
  user: UserListEntryType;
};

export default ({ user }: UserListEntryPropType) => {
  return (
    <tbody>
      <tr>
        <td>
          {user.surname}, {user.name}
        </td>
        <td>
          {user.isAdmin && <Chip label="admin" size="small" />}
          {user.isActive || <Chip label="inactive" size="small" color="error" />}
        </td>
        <td>{user.companyName}</td>
        <td>{user.email}</td>
      </tr>
    </tbody>
  );
};
